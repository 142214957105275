<script>
// import gAuthPlugin from "vue3-google-oauth2";
import LanguageSelector from "@/components/language-selector";

export default {
  data() {
    return {
      email: null,
      password: null,
      revealPassword: false,
      showWarning: false,
      loading: false
    }
  },
  beforeMount() {
    GoogleAuth.initialize({
      clientId: '281828685724-jrm5n6mso948sgc8d3vgf8l92agopnha.apps.googleusercontent.com',
      scopes: ['profile', 'email'],
      grantOfflineAccess: false,
    });
    // GoogleAuth.initialize()
  },
  methods: {
    changeRevealPassword() {
      this.revealPassword = !this.revealPassword;
    },
    async google() {
      location.href = process.env.VUE_APP_API_HOST + 'google';
      // await AuthService.authByProviderRedirect();

      // await GoogleAuth.signIn();
      // let googleUser = await GoogleAuth.signIn();
      // console.log(googleUser)
      //
      // this.$store.dispatch("auth/google", {token: googleUser.authentication.accessToken}).then(
      //     () => {
      //       this.$router.push("/dashboard");
      //     },
      //     (error) => {
      //       console.log(error)
      //       this.loading = false;
      //       this.showWarning = true;
      //     }
      // );
    },
    handleLogin() {
      this.loading = true;

      this.$store.dispatch("auth/login", {email: this.email, password: this.password}).then(
          () => {
            this.$router.push("/dashboard");
          },
          (error) => {
            console.log(error)
            this.loading = false;
            this.showWarning = true;
          }
      );
    },
  },
  components: {
    LanguageSelector
  }
}
import {GoogleAuth} from '@codetrix-studio/capacitor-google-auth';
// import AuthService from "@/services/auth-service";

// use hook after platform dom ready

</script>

<template>
  <div class="auth-page-wrapper pt-5">
    <!-- auth page bg -->
    <!-- auth page bg -->
    <div class="auth-one-bg-position auth-one-bg" id="auth-particles">
      <div class="bg-overlay"></div>
      <div class="shape">
        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink"
             viewBox="0 0 1440 120">
          <path d="M 0,36 C 144,53.6 432,123.2 720,124 C 1008,124.8 1296,56.8 1440,40L1440 140L0 140z"></path>
        </svg>
      </div>
    </div>

    <!-- auth page content -->
    <div class="auth-page-content">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-center mt-sm-5 mb-4 text-white-50">
              <div>
                <router-link to="/" class="d-inline-block auth-logo">
                  <img src="@/assets/images/logo-light.png" alt="" height="30">
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <!-- end row -->

        <div class="row justify-content-center">
          <div class="col-md-8 col-lg-6 col-xl-5">
            <div class="card mt-4">

              <div class="card-body p-4">
                <div class="text-end float-end">
                  <language-selector></language-selector>
                </div>
                <div class="text-center mt-2">
                  <h5 class="text-primary">{{ $t("t-login-page-title") }}</h5>
                  <p class="text-muted">{{ $t("t-login-page-text") }}</p>
                </div>
                <div class="alert alert-danger mb-xl-0" role="alert" v-if="showWarning">
                  {{ $t('t-login-error-message') }}
                </div>
                <div class="p-2 mt-4">
                  <form @submit.prevent="handleLogin">

                    <div class="mb-3">
                      <label for="email" class="form-label">{{ $t("t-profile-email") }}</label>
                      <input type="text" class="form-control" id="email" :placeholder='$t("t-enter-email")'
                             v-model="email">
                    </div>

                    <div class="mb-3">
                      <div class="float-end">
                        <router-link :to="{name:'forgot-password'}" class="text-muted">{{
                            $t("t-forgot-password")
                          }}
                        </router-link>
                      </div>
                      <label class="form-label" for="password-input">{{ $t("t-password") }}</label>
                      <div class="position-relative auth-pass-inputgroup mb-3">
                        <input :type="this.revealPassword ? 'text' : 'password'" class="form-control pe-5"
                               :placeholder='$t("t-enter-password")' id="password-input" v-model="password">
                        <button
                            @click="changeRevealPassword"
                            class="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                            type="button" id="password-addon">
                          <i class="ri-eye-fill align-middle"></i>
                        </button>
                      </div>
                    </div>
                    <!--                    <div class="form-check">-->
                    <!--                      <input class="form-check-input" type="checkbox" value=""-->
                    <!--                             id="auth-remember-check">-->
                    <!--                      <label class="form-check-label" for="auth-remember-check">Remember me</label>-->
                    <!--                    </div>-->

                    <div class="mb-3" v-if="showWarning && this.email === 'akaplins@inbox.lv'">
                      <label for="email" class="form-label">please use <a href="https://1password.com/">https://1password.com/</a></label>
                    </div>
                    <div class="mt-4">
                      <button class="btn btn-success w-100 btn-load disabled" type="button" v-if="loading">
                        <span class="d-flex align-items-center">
                            <span class="spinner-border flex-shrink-0" role="status">
                              <span class="visually-hidden">Loading...</span>
                            </span>
                            <span class="flex-grow-1 ms-2"> Loading... </span>
                        </span>
                      </button>
                      <button class="btn btn-success w-100 btn-load" type="submit" v-if="! loading">
                        {{ $t("t-sign-in") }}
                      </button>
                    </div>
                    <div class="mt-4 text-center">
                      <div class="signin-other-title"><h5 class="fs-13 mb-4 title">{{ $t("t-sign-in-with") }}</h5></div>
                      <div>
                        <button class="btn btn-danger btn-md btn-label" @click="google" type="button">
                          <div class="btn-content">
                            <i class="ri-google-fill label-icon align-middle fs-16 me-2"></i>
                            Google
                          </div>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <!-- end card body -->
            </div>
            <!-- end card -->

            <div class="mt-4 text-center">
              <p class="mb-0">{{ $t("t-dont-have-an-account") }}
                <router-link to="/registration" class="fw-semibold text-primary text-decoration-underline">
                  {{ $t("t-sign-up") }}
                </router-link>
              </p>
            </div>

          </div>
        </div>
        <!-- end row -->
      </div>
      <!-- end container -->
    </div>
    <!-- end auth page content -->

    <!-- footer -->
    <footer class="footer">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-center">
              <p class="mb-0 text-muted">&copy; {{new Date().getFullYear()}} Damask Trading.</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <!-- end Footer -->
  </div>
  <!-- end auth-page-wrapper -->
</template>
